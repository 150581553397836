<template>
    <div class="container my-6">
        <h1 class="font-bold text-lg">Error {{ code }}</h1>
        <p v-if="code === 401">Unauthorised</p>
        <p v-if="code === 403">Forbidden</p>
        <p v-if="code === 404">Page Not Found</p>
        <p v-if="code === 500">Internal Server Error</p>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    computed: {
        ...mapState(['errorCode']),
        code() {
            return this.errorCode || 404
        }
    }
}
</script>